import { useMutation, useQueryClient } from '@tanstack/react-query'

import { removeWatcher } from '../api/removeWatcher'

import type { getWatchers } from '../api/getWatchers'

type Data = Awaited<ReturnType<typeof getWatchers>> | undefined
export function useRemoveWatcherMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async ({
      taskId,
      userId,
    }: {
      taskId: string
      userId: string
    }) => removeWatcher(taskId, userId),
    async onMutate({ taskId, userId }) {
      await qc.cancelQueries({ queryKey: ['watchers', taskId] })
      // @ts-ignore
      qc.setQueryData<Data>(['watchers', taskId], (old) =>
        // @ts-ignore
        ({
          ...old,
          watchers: [...old?.watchers.filter((item) => item.user !== userId)],
        }),
      )
    },
  })
}
