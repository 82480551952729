import Pristine from 'pristinejs'

import Schedule from '@components/Schedule'
import Task from '@routes/Tasks/Task'

import { Duplicate } from '../@components/Duplicate'
import { Watchers } from '../@components/Watchers'
import { mountRegularReact } from '../@utils/mountReact'
import { Tasks, Teams } from '../entities/tasks'

const $document = $(document)
const taskCountDown = $('.task-countdown')
$document.ready(() => {
  if ($('.details-preview-box a.uploaded-image').length > 0) {
    $('.details-preview-box').removeClass('hidden')
    $('.details-preview-box a.uploaded-image').simpleLightbox()
  }

  if ($('.details-preview-box div.file-name').length > 0) {
    $('.details-preview-box').removeClass('hidden')
  }

  if ($('#task_title').length > 0) {
    $('#task_title').focus()
  }

  if (
    Teams !== undefined && //Current task widget reload
    $('.current-task').length > 0
  ) {
    Teams.loadCurrentTask()
  }

  //Events for tasks lists pages
  if (
    $('.tasks-list, .last-tasks').length > 0 &&
    Teams !== undefined &&
    $('.last-tasks').length > 0
  ) {
    Teams.reloadLastTasksWidget()
  }

  //Task deadline countdown
  if (
    taskCountDown.length > 0 &&
    typeof countDown !== 'undefined' &&
    jQuery().countdown &&
    typeof taskFormat !== 'undefined'
  ) {
    $('.task-countdown').countdown(countDown, function (event) {
      $(this).html(event.strftime(taskFormat))
    })
  }

  //Close task
  $document.on('click', '.close-task', function (e) {
    e.preventDefault()

    if (typeof taskId === 'undefined') {
      taskId = $(this).data('task-id')
    }

    if (!taskId) {
      return
    }

    Task.close(taskId)
  })

  //Mark as done
  $document.on('click', '.task-done', function (e) {
    e.preventDefault()

    if (typeof taskId === 'undefined') {
      taskId = $(this).data('task-id')
    }

    if (!taskId) {
      return
    }

    Tasks.changeStatus(taskId, 4)
  })

  //Mark as default
  $document.on('click', '.task-default', function (e) {
    e.preventDefault()

    if (typeof taskId === 'undefined') {
      taskId = $(this).data('task-id')
    }

    if (!taskId) {
      return
    }

    Tasks.changeStatus(taskId, 1)
  })

  //Toggle Employee form
  $document.on('click , touch', '.invitation-form-box .title-link', (e) => {
    e.preventDefault()
    $('.toggle-box').slideToggle()
    $('.invitation-form-box').toggleClass('active')
    $('.close-icon , .open-icon').toggle()
  })

  const taskForm = $document.find('form[name=task]')
  let schedule = null
  let pristine = null

  if (taskForm.length > 0) {
    Tasks.initUpload(taskForm)

    if (
      (taskId === 'undefined' || taskId === '') &&
      $('.schedules').length > 0
    ) {
      schedule = new Schedule()
    }

    pristine = new Pristine(taskForm.get(0))
  }

  $document.on('click', '#task_submit', (e) => {
    e.preventDefault()

    const TASK_ID =
      typeof taskId === 'undefined' || taskId === '' ? '0' : taskId
    const parentForm = $(e.target).parents('form')

    if (parentForm.length > 0 && pristine && pristine.validate()) {
      const formData = new FormData(parentForm[0])

      if (schedule && schedule.validate()) {
        const { query } = schedule.prepareQuery()
        formData.append('taskSchedule', JSON.stringify(query))
      }

      Tasks.create(TASK_ID, formData, $(e.target))
    }

    return false
  })

  mountRegularReact(Watchers, document.querySelectorAll('.js-react-watcher')[0])
  mountRegularReact(
    Duplicate,
    document.querySelectorAll('.js-react-duplicate')[0],
  )

  $document.on('click', '.mobile-toggle-button', (e) => {
    e.preventDefault()
    $('.details-toggle-box').slideToggle('fast')
    $('#details-show').toggleClass('hide')
    $('#details-hide').toggleClass('hide')
  })

  $document.on('click', '.mobile-buttons-group', (e) => {
    e.preventDefault()
    e.stopPropagation()
    $('.buttons-group').toggleClass('active')
  })
  $document.on('click', 'body', () => {
    $('.buttons-group').removeClass('active')
  })

  $document.on('click', '.top-widget-bar .widget-item', function (e) {
    if ($(this).hasClass('active') && $(e.target).is('span[class^="icon-"]')) {
      $('.top-widget-bar .widget-item').removeClass('active')
    } else {
      $('.top-widget-bar .widget-item').removeClass('active')
      $(this).addClass('active')
    }
  })
  $document.on('click', '.content-box', () => {
    $('.top-widget-bar .widget-item').removeClass('active')
  })

  $document.on('click', '.existent-attachment-remove', function () {
    if (typeof taskId !== 'undefined') {
      const existentAttachment = $(this).closest('.existent-attachment')
      const attachmentId = existentAttachment.data('id')
      if (attachmentId) {
        existentAttachment.addClass('hidden')
        Tasks.removeAttachment(taskId, attachmentId)
      }
    }
  })

  const $slider = $('.mobile-preview-box')
  if ($slider.length > 0) {
    let currentSlide
    let slidesCount
    const sliderCounter = document.createElement('div')
    sliderCounter.classList.add('slider__counter')

    const updateSliderCounter = function (slick, currentIndex) {
      currentSlide = slick.slickCurrentSlide() + 1
      slidesCount = slick.slideCount
      $(sliderCounter).text(`${currentSlide} / ${slidesCount}`)
    }

    $slider.on('init', (event, slick) => {
      $slider.append(sliderCounter)
      updateSliderCounter(slick)
    })

    $slider.on('afterChange', (event, slick, currentSlide) => {
      updateSliderCounter(slick, currentSlide)
    })

    $slider.slick({
      arrows: false,
    })
  }

  const watchersSelect = $('#task_watcherIds')

  watchersSelect.select2({
    placeholder: '',
    placeholderForSearch: Translator.trans('filter_by.filter-items'), // additional placeholder for search box
    closeOnSelect: false,
    // Make selection-box similar to single select
    selectionAdapter: $.fn.select2.amd.require(
      'MultipleCustomSelectionAdapter',
    ),
    templateSelection: (data) =>
      `${data.selected.length} ${Translator.trans('filter_by.selected')}`,
    // Add search box in dropdown
    dropdownAdapter: $.fn.select2.amd.require('MultipleCustomDropdownAdapter'),
    width: '100%',
  })

  watchersSelect.on('select2:open', () => {
    setTimeout(
      () => document.querySelector('.select2-search__field').focus(),
      0,
    )
  })

  $('#task_assigneeIds').select2({
    placeholder: '',
    placeholderForSearch: Translator.trans('filter_by.filter-items'), // additional placeholder for search box
    closeOnSelect: false,
    // Make selection-box similar to single select
    selectionAdapter: $.fn.select2.amd.require(
      'MultipleCustomSelectionAdapter',
    ),
    templateSelection: (data) =>
      `${data.selected.length} ${Translator.trans('filter_by.selected')}`,
    // Add search box in dropdown
    dropdownAdapter: $.fn.select2.amd.require('MultipleCustomDropdownAdapter'),
    width: '100%',
  })

  $('#user-team-switcher').select2({
    dropdownCssClass: 'team-switcher',
    width: '100%',
  })

  $(
    '#reports-subscription-teammates, #timeframe-select, #team-switcher, .user-language-switcher',
  ).select2()

  $(document).on('click', '.task-start, .details-task-start', () => {
    $(document).trigger('timer-start')
  })
})
