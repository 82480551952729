import { useMutation, useQueryClient } from '@tanstack/react-query'

import { addWatcher } from '../api/addWatcher'

import type { Response } from '../api/getWatchers'

export function useAddWatcherMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async ({
      taskId,
      userId,
    }: {
      taskId: string
      userId: string
    }) => addWatcher(taskId, userId),
    async onMutate({ taskId, userId }) {
      await qc.cancelQueries({ queryKey: ['watchers', taskId] })
      // @ts-ignore
      qc.setQueryData<Response>(['watchers', taskId], (old) =>
        // @ts-ignore
        ({
          ...old,
          watchers: [...old?.watchers, { canEdit: true, user: userId }],
        }),
      )
    },
    onSettled(_, __, { taskId }) {
      qc.invalidateQueries({ queryKey: ['watchers', taskId] })
    },
  })
}
