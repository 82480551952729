import { useMutation, useQueryClient } from '@tanstack/react-query'
import { duplicate } from '../api/duplicate'
import { getDuplicates } from '../api/getDuplicates'

type Return = Awaited<ReturnType<typeof getDuplicates>>
export function useDuplicateMutation() {
  const qc = useQueryClient()
  return useMutation({
    async mutationFn({ taskId, userId }: { taskId: string; userId: string }) {
      return duplicate(taskId, userId)
    },
    async onMutate({ taskId, userId }) {
      await qc.cancelQueries({ queryKey: ['duplicates', taskId] })
      qc.setQueryData<Return | undefined>(['duplicates', taskId], (oldData) => {
        if (!oldData) {
          return oldData
        }

        const index = oldData.data.findIndex((item) => item.value === userId)

        if (index === -1) {
          return oldData
        }

        const result = { data: [...oldData.data] }
        result.data.splice(index, 1, { ...oldData.data[index], duplicateUrl: undefined })
        return result
      })
    },
    async onSettled(data, error, { taskId }) {
      await qc.invalidateQueries({ queryKey: ['duplicates', taskId] })
    },
  })
}
