import { AddButton } from './AddButton/AddButton'
import { Label } from './Label'
import { List } from './List'

export function Root({ taskId }: { readonly taskId: string }) {
  return (
    <List
      label={<Label>{Translator.trans('widgets.team.watchers_title')}</Label>}
      taskId={taskId}
    >
      <AddButton taskId={taskId} />
    </List>
  )
}
