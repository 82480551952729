import ky from 'ky'

export async function addWatcher(taskId: string, userId: string) {
  const body = new FormData()
  body.append('watcherid', userId)

  return ky(Routing.generate('app_task_watchers_load', { taskId }), {
    method: 'POST',
    body,
  }).json<void>()
}
