import { ReactNode } from 'react'
import { ListItem } from './ListItem'
import { useActiveOptionsQuery } from '../hooks/useActiveOptionsQuery'
import { useRemoveWatcherMutation } from '../hooks/useRemoveWatcherMutation'
import { Skeleton } from '../../Skeleton/Skeleton'
import { useCanAddQuery } from '../hooks/useCanAddQuery'
import { useCanRemoveWatcherQuery } from '../hooks/useCanRemoveWatcherQuery'

export function List({ taskId, children, label }: { taskId: string; children: ReactNode; label: ReactNode }) {
  const { data } = useActiveOptionsQuery(taskId)
  const { data: canRemoveWatcher } = useCanRemoveWatcherQuery(taskId)
  const { data: canAdd } = useCanAddQuery(taskId)
  const { mutate: removeWatcher } = useRemoveWatcherMutation()

  return (
    <div className={'tw-flex tw-flex-wrap tw-gap-2 tw-text-base  tw-items-center'}>
      {label}
      {!data && (
        <>
          <Skeleton className={'tw-h-4 tw-w-[150px]'} /> <Skeleton className={'tw-h-4 tw-w-[50px]'} />
        </>
      )}

      {data &&
        data?.map((item) => {
          return (
            <ListItem
              onClick={canRemoveWatcher?.[item.value] ? () => removeWatcher({ taskId, userId: item.value }) : undefined}
              key={item.value}
              label={item.label}
              // @ts-ignore
              image={item.image}
              canHover={canRemoveWatcher?.[item.value] ?? true}
            />
          )
        })}
      {data && canAdd && children}
    </div>
  )
}
