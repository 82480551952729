import { CopyIcon } from '@radix-ui/react-icons'

import { TooltipPrimitives } from '@components/Tooltip'

import type { WatcherOption } from '../../../types'
import type { IndicatorsContainerProps } from 'react-select'

export function IndicatorsContainer<Option extends WatcherOption>(
  props: IndicatorsContainerProps<Option, true, never>,
) {
  return (
    <TooltipPrimitives.Provider>
      <TooltipPrimitives.Root>
        <TooltipPrimitives.Trigger asChild>
          <CopyIcon className="tw-text-primary-blue" height={24} width={24} />
        </TooltipPrimitives.Trigger>
        <TooltipPrimitives.Content side="right">
          {Translator.trans('task.duplicate-tooltip')}
        </TooltipPrimitives.Content>
      </TooltipPrimitives.Root>
    </TooltipPrimitives.Provider>
  )
}
