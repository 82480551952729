import ky from 'ky'

export type Response = {
  canAdd: boolean
  teammates: Array<{
    id: string
    avatar: string
    firstName: string
    lastName: string
    nickname: string
  }>
  watchers: Array<{
    canRemove: boolean
    user: string
  }>
}
export async function getWatchers(taskId: string) {
  return ky(Routing.generate('app_task_watchers_load', { taskId, _format: 'json' })).json<Response>()
}
