import { useWatchersFetchQuery } from './useWatchersFetchQuery'
import { createOptionFromResponse } from '../utils/createOption'

export function useActiveOptionsQuery(taskId: string) {
  // @ts-ignore
  return useWatchersFetchQuery(taskId, (data) => {
    const ids = data.watchers.map((row) => row.user)
    return data.teammates.filter((mate) => ids.includes(mate.id)).map((item) => createOptionFromResponse(item))
  })
}
