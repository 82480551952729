import { CrossCircledIcon } from '@radix-ui/react-icons'
import { ListItem as GenericListItem } from '../../OverflowMultiSelect/components/GenericListItem'
import { ComponentProps, useState } from 'react'
import { cn } from '../../../@utils/cn'
export function ListItem({
  image,
  canHover,
  ...props
}: ComponentProps<typeof GenericListItem> & { canHover: boolean }) {
  const [isHovered, setHover] = useState(false)
  return (
    <GenericListItem
      className={cn('tw-cursor-pointer tw-px-1 tw-rounded-xl ', {
        'tw-cursor-not-allowed': !canHover,
        'hover:tw-bg-white': canHover,
      })}
      onMouseEnter={() => canHover && setHover(true)}
      onMouseLeave={() => canHover && setHover(false)}
      {...props}
      // @ts-ignore
      image={isHovered ? <CrossCircledIcon width={'100%'} height={'100%'} /> : image}
    />
  )
}
