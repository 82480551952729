import { ComponentProps } from 'react'
import { ContentWrapper as ParentContentWrapper } from '../../../../OverflowMultiSelect/components/GenericListItem'
import { OptionUrl } from './OptionUrl'

type Props = ComponentProps<typeof ParentContentWrapper> & { item: { duplicateUrl: string } }
export function UrlContentWrapper({ children, ...props }: Props) {
  const { item } = props
  return (
    <ParentContentWrapper {...props}>
      {item.duplicateUrl ? <OptionUrl url={item.duplicateUrl}>{children}</OptionUrl> : children}
    </ParentContentWrapper>
  )
}
