import { components, ControlProps, ValueContainerProps } from 'react-select'
import { WatcherOption } from '../../../types'
import React from 'react'
import { cn } from '../../../../../@utils/cn'
export function Control<Option extends WatcherOption>({
  className,
  children,
  ...props
}: ControlProps<Option, true, never>) {
  return (
    <components.Control
      className={cn(
        'tw-border-transparent tw-shadow-none  tw-outline-none tw-bg-transparent',
        'tw-flex-row-reverse tw-cursor-pointer tw-min-h-0',
        className
      )}
      {...props}
    >
      {children}
    </components.Control>
  )
}
