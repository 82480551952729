import { createContext, useContext } from 'react'

const Context = createContext<null | {
  onDuplicateClick: undefined | (() => Promise<void>)
}>(null)

export const useAddButtonContext = () => {
  const currentUserContext = useContext(Context)

  if (!currentUserContext) {
    throw new Error('useCurrentUser has to be used within <CurrentUserContext.Provider>')
  }

  return currentUserContext
}

export const { Provider: AddButtonContextProvider } = Context
