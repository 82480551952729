import { GroupBase, MenuProps } from 'react-select'
import { MenuWithFilter as ParentMenu } from '@components/Form/components/Select/components/MenuWithFilter'
import { useAddButtonContext } from '../../../hooks/useAddButtonContext'
import { DropdownButton } from '../../DropdownButton'

export function Menu<Option>({
  children,
  ...props
}: MenuProps<Option, true, GroupBase<Option>>) {
  const { onDuplicateClick } = useAddButtonContext()
  return (
    <ParentMenu {...props}>
      {children}
      <DropdownButton onClick={onDuplicateClick} />
    </ParentMenu>
  )
}
