import { useQuery } from '@tanstack/react-query'

import { getWatchers } from '../api/getWatchers'

import type { Response } from '../api/getWatchers'

export function useWatchersFetchQuery<TData = Response>(
  taskId: string,
  select?: (data: Response) => TData,
) {
  return useQuery({
    queryKey: ['watchers', taskId],
    queryFn: async () => getWatchers(taskId),
    select,
    placeholderData() {
      return { teammates: [], watchers: [], canAdd: true }
    },
  })
}
