import { AddButton } from './AddButton/AddButton'

export function Root({
  taskId,
  title,
}: {
  readonly taskId: string
  readonly title: string
}) {
  return (
    <div className="tw-flex tw-space-x-2">
      <div>{title}</div>
      <AddButton taskId={taskId} />
    </div>
  )
}
