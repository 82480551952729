import { useQuery } from '@tanstack/react-query'
import { getDuplicates } from '../api/getDuplicates'

export function useGetDuplicates<TData = Response>(taskId: string) {
  return useQuery({
    queryKey: ['duplicates', taskId],
    queryFn: () => getDuplicates(taskId),
    select: (response) => response.data,
    placeholderData() {
      return { data: [] }
    },
  })
}
