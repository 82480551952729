import { MultiSelect } from '../../../OverflowMultiSelect/components/MultiSelect'
import { Control } from './components/Control'
import { ValueContainer } from './components/ValueContainer'
import { IndicatorsContainer } from './components/IndicatorsContainer'
import { useAddWatcherMutation } from '../../hooks/useAddWatcherMutation'
import { useAllOptionsQuery } from '../../hooks/useAllOptionsQuery'
import { useActiveOptionsQuery } from '../../hooks/useActiveOptionsQuery'
import { useRemoveWatcherMutation } from '../../hooks/useRemoveWatcherMutation'

const IndicatorSeparator = () => null
export function AddButton({ taskId }: { taskId: string }) {
  const { data: options } = useAllOptionsQuery(taskId)
  const { data: values } = useActiveOptionsQuery(taskId)

  const { mutate: addWatcher } = useAddWatcherMutation()
  const { mutate: removeWatcher } = useRemoveWatcherMutation()

  return (
    <div>
      <MultiSelect
        options={options}
        isClearable={false}
        onChange={(newValue, actionMeta) => {
          switch (actionMeta.action) {
            case 'select-option':
              addWatcher({ taskId, userId: actionMeta.option!.value })
              break

            case 'deselect-option':
              removeWatcher({ taskId, userId: actionMeta.option!.value })
              break
          }
        }}
        value={values}
        // @ts-ignore
        components={{ Control, ValueContainer, IndicatorSeparator, IndicatorsContainer }}
      />
    </div>
  )
}
