import { useState } from 'react'
import { components } from 'react-select'

import { Control } from './components/Control'
import { IndicatorSeparator } from './components/IndicatorSeparator'
import { IndicatorsContainer } from './components/IndicatorsContainer'
import { Menu } from './components/Menu'
import { Option } from './components/Option'

import { MultiSelect } from '../../../OverflowMultiSelect/components/MultiSelect'
import { AddButtonContextProvider } from '../../hooks/useAddButtonContext'
import { useDuplicateMutation } from '../../hooks/useDuplicateMutation'
import { useGetDuplicates } from '../../hooks/useGetDuplicates'

export function AddButton({ taskId }: { readonly taskId: string }) {
  const { data: options } = useGetDuplicates(taskId)
  const savedValues =
    options?.filter((option) => Object.hasOwn(option, 'duplicateUrl')) || []
  const [unsavedValues, setUnsavedValues] = useState<
    NonNullable<typeof options>
  >([])
  const { isPending, mutateAsync, variables } = useDuplicateMutation()
  const saveableItems = unsavedValues.filter(
    ({ value }) => savedValues.findIndex((item) => item.value === value) === -1,
  )
  const onDuplicateClick = async () => {
    for (const { value: userId } of saveableItems) {
      await mutateAsync({ taskId, userId })
    }
  }

  const values = [
    ...savedValues.filter(
      (savedValue) =>
        unsavedValues.findIndex(
          (unsavedValue) => unsavedValue.value === savedValue.value,
        ) === -1,
    ),
    ...unsavedValues.map(
      (unsavedValue) =>
        savedValues.find(
          (savedValue) => savedValue.value === unsavedValue.value,
        ) ?? unsavedValue,
    ),
  ]

  return (
    <AddButtonContextProvider
      value={{
        onDuplicateClick:
          saveableItems.length > 0 && !isPending ? onDuplicateClick : undefined,
      }}
    >
      <div>
        <MultiSelect
          classNames={{
            valueContainer: () => 'tw-p-0',
            control: () => 'tw-p-[0px]',
          }}
          components={{
            // @ts-expect-error
            Control,
            ValueContainer: components.ValueContainer,
            IndicatorSeparator,
            // @ts-expect-error
            IndicatorsContainer,
            Option,
            Menu,
          }}
          hideSelectedOptions={false}
          isClearable={false}
          isOptionDisabled={(option) => savedValues.includes(option)}
          onChange={(newValue, actionMeta) => {
            setUnsavedValues(
              newValue.filter((value) => !savedValues.includes(value)),
            )
          }}
          options={options}
          value={values}
        />
      </div>
    </AddButtonContextProvider>
  )
}
