import { components, ValueContainerProps } from 'react-select'
import { WatcherOption } from '../../../types'
import React from 'react'
import cx from 'classnames'
export function ValueContainer<Option extends WatcherOption>({
  className,
  children,
  ...props
}: ValueContainerProps<Option, true, never>) {
  return (
    <components.ValueContainer className={cx(className, 'tw-flex')} {...props}>
      <span className={'tw-text-primary-blue tw-font-bold'}>{Translator.trans('buttons.add')}</span>
      {children}
    </components.ValueContainer>
  )
}
