import { GroupBase, OptionProps } from 'react-select'
import * as Checkbox from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import { TwSpinner } from '../../../../TwSpinner/TwSpinner'
import { cn } from '../../../../../@utils/cn'
import { UrlContentWrapper } from './UrlContentWrapper'
import { OptionWrapper } from '../../../../OverflowMultiSelect/components/OptionWrapper'
export function Option<Option extends { duplicateUrl?: string }>({
  children,
  ...props
}: OptionProps<Option, true, GroupBase<Option>>) {
  return (
    <OptionWrapper
      {...props}
      labelProps={{
        components: {
          // @ts-ignore
          ContentWrapper: UrlContentWrapper,
        },
      }}
      checkbox={({ className }) => {
        return (
          <Checkbox.Root
            disabled={props.isDisabled}
            checked={props.isSelected}
            className={cn(className, {
              'data-[state=checked]:tw-border-transparent hover:tw-border-transparent': Object.hasOwn(
                props.data,
                'duplicateUrl'
              ),
              'tw-bg-primary-green': props.data.duplicateUrl,
            })}
          >
            <Checkbox.Indicator
              className={typeof props.data.duplicateUrl === 'string' ? 'tw-text-white' : 'tw-text-primary-blue'}
            >
              {Object.hasOwn(props.data, 'duplicateUrl') && props.data.duplicateUrl === undefined ? (
                <TwSpinner className={'tw-w-16'} />
              ) : (
                <CheckIcon />
              )}
            </Checkbox.Indicator>
          </Checkbox.Root>
        )
      }}
    />
  )
}
