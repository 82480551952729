import { HTMLProps } from 'react'

export function DropdownButton({ onClick }: Pick<HTMLProps<HTMLButtonElement>, 'onClick'>) {
  return (
    <div className="tw-p-4 tw-relative tw-border-t-primary-table-border-color tw-border-solid tw-border-t">
      <button
        onClick={onClick}
        type="button"
        disabled={!onClick}
        className="tw-inline-flex tw-items-center tw-w-full tw-text-center
          tw-justify-center tw-py-4 tw-border tw-border-transparent tw-text-base
          tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-primary-blue
          hover:tw-bg-[#313d48] focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500
          disabled:tw-bg-primary-lightgrey
          "
      >
        {Translator.trans('task.duplicate')}
      </button>
    </div>
  )
}
